import React from 'react'

export default function Ownership() {
    return (
        <>
            <div id="uni_process" className="uni-process uk-section uk-section-xlarge@m uk-panel">
                <div className="uk-container">
                    {/* <header className="uk-grid-xsmall uk-flex-center uk-flex-middle uk-grid" data-uk-grid data-anime="opacity:[0, 1]; translateY:[24, 0]; onview: true; delay: 100;">
                        <div className="uk-panel uk-text-center">
                            <h2 className="uk-h3 uk-h1@m">How it <span className="uk-text-gradient">works!</span></h2>
                        </div>
                    </header> */}
                    <div className="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                        <div className="uk-grid uk-grid-2xlarge uk-grid-3xlarge@m uk-child-width-1-1" data-uk-grid>
                            <div>
                                <div className="uk-panel">
                                    <div className="uk-grid uk-grid-3xlarge@m uk-flex-middle uk-child-width-1-2@m" data-uk-grid>
                                        <div>
                                            <div className="uk-panel uk-image-middle uk-overflow-hidden uk-radius uk-radius-large@m" data-anime="opacity:[0, 1]; translateX:[-24, 0]; onview: -250; delay: 200;">
                                                <img src="../assets/images/ownership.png" alt="" />
                                            </div>
                                        </div>
                                        <div className="uk-flex-first@m">
                                            <div className="uk-panel" data-anime="opacity:[0, 1]; translateX:[24, 0]; onview: -250; delay: 300;">
                                                {/* <span className="uk-h6 uk-h5@m uk-text-gradient">02.</span> */}
                                                <h3 className="uk-h3 uk-h2@m">Empowering Digital Ownership</h3>
                                                <p className="uk-text-large@m uk-margin-medium-top@m">ZAP Protocol, a forward-thinking blockchain company dedicated to pioneering cutting-edge decentralized applications, is thrilled to unveil its groundbreaking Staking Platform, designed to operate seamlessly on the BSC Chain.</p>
                                                <p className="uk-text-large@m">Our platform empowers users to maximize their ZAP holdings by participating in a secure and decentralized staking process, offering attractive rewards.</p>
                                                <a href="https://stake.zapfinance.org/" target='_blank' className="uk-button uk-button-large@m uk-button-gradient uk-margin-small-top">
                                                    <span>Affiliate</span>
                                                    <i className="uk-icon-small unicon-arrow-up-right uk-text-bold" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
