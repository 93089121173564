import React, { useEffect, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button';

export default function Header() {
    const [show, setShow] = useState(false);
    const handleClickShow = () => {
        setShow(true);
    }
    const handleClickClose = () => {
        setShow(false);
    }
    const [showmenu, setShowmenu] = useState(false);
    const handleClickShowMenu = () => {
        setShowmenu(true);
    }
    const handleClickCloseMenu = () => {
        setShowmenu(false);
    }
    const popoverTop = (
        <Popover id="popover-positioned-top" className='popover' title="Popover top">
            <strong><a href="#faq" style={{ fontSize: "12px" }}>Need <br /> Help ?</a></strong>
        </Popover>
    );
    return (
        <>
            <div>
                {/* <div className="darkmode-trigger uk-position-bottom-right uk-position-small uk-position-fixed uk-box-shadow-large uk-radius-circle" data-darkmode-toggle>
                    <label className="switch">
                        <span className="sr-only">Dark mode toggle</span>
                        <input type="checkbox" />
                        <span className="slider" />
                    </label>
                </div> */}
                <header className="uni-header uk-position-top">
                    <div className="uni-header-navbar" data-uk-sticky="top: 70; show-on-up: false; animation: uk-animation-slide-top" data-anime="opacity:[0, 1]; translateY:[-24, 0]; onview: true; delay: 0;">
                        <div className="uk-container">
                            <nav className="uk-navbar uk-navbar-container uk-navbar-transparent" data-uk-navbar>
                                <div className="uk-navbar-top">
                                    <div className="uk-navbar-left">
                                        <a className="uk-logo uk-navbar-item uk-h4 uk-h3@m uk-margin-remove" href="/"> <img className="uk-visible dark:uk-hidden" width={120} src="/assets/images/logo.png" alt="Nerko" loading="lazy" /> <img className="uk-hidden dark:uk-visible" width={120} src="assets/images/white-logo.png" alt="Nerko" loading="lazy" /> </a>
                                    </div>
                                    <div className="uk-navbar-right uk-flex-1 uk-flex-right">
                                        <ul className="uk-navbar-nav dark:uk-text-gray-10 uk-visible@m" data-uk-scrollspy-nav="closest: li; scroll: true; offset: 80" data-uk-navbar-bound>
                                            <li><a href="#About">About Us</a></li>
                                            <li><a href="#Tokenomics">Tokenomics</a></li>
                                            <li><a href="#P2P">P2P</a></li>
                                            <li><a href="https://zap.gitbook.io/zap-finance/" target='_blank'>Whitepaper</a></li>
                                        </ul>
                                        <div className="uk-navbar-item">
                                            {/* <ul className="uk-subnav uk-subnav-small uk-visible@m">
                                                <li>
                                                    <a href="https://twitter.com/unistudioco"><i className="uk-icon uk-icon-medium unicon-logo-twitter" /></a>
                                                </li>
                                                <li>
                                                    <a href="https://discord.com/unistudioco"><i className="uk-icon uk-icon-medium unicon-logo-discord" /></a>
                                                </li>
                                            </ul> */}
                                            <a href="https://stake.zapfinance.org/" target='_blank' className="uk-button uk-button-medium@m uk-button-default uk-button-outline uk-margin-left uk-visible@l">
                                                <span>Start Now</span>
                                            </a>
                                        </div>
                                        <div className="uk-navbar-item uk-hidden@m">
                                            {/* <a href="#uni_connect_wallet" className="uk-button uk-button-medium@m uk-button-icon uk-hidden@m uk-margin-small-right" data-uk-toggle>
                                                <i className="uk-icon unicon-wallet" />
                                            </a> */}
                                            <a onClick={handleClickShowMenu}>
                                                <span className="uk-icon uk-icon-medium material-icons">menu</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                <div className={showmenu ? "uni-mobile-menu uk-offcanvas uk-offcanvas-overlay uk-open" : "uni-mobile-menu uk-offcanvas uk-offcanvas-overlay"} data-uk-offcanvas="mode: push; overlay: true; flip: true; selPanel: .uk-offcanvas-bar-panel;" style={showmenu ? { display: 'block' } : { display: 'none' }}>
                    <div className="uk-offcanvas-bar-panel uk-panel dark:uk-background-gray-100 uk-offcanvas-bar-animation uk-offcanvas-push">
                        <div className="uni-mobile-menu-wrap uk-flex-column uk-flex-between" data-uk-height-viewport="offset-bottom: true;" style={{ minHeight: 'calc(100vh + 0px)' }}>
                            <div className="uni-mobile-menu-content">
                                <header className="uk-card uk-card-2xsmall uk-flex-middle uk-flex-between">
                                    <div className="uk-flex-1" onClick={handleClickCloseMenu}>
                                        <button aria-label="Close Menu" className="uk-offcanvas-close uk-button uk-button-small uk-button-icon uk-button-default uk-button-outline uk-radius-circle" type="button">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left icon uk-icon-small"><line x1={19} y1={12} x2={5} y2={12} /><polyline points="12 19 5 12 12 5" /></svg>
                                        </button>
                                    </div>
                                    <div>
                                        <img className="uk-hidden dark:uk-visible" width={120} src="assets/images/white-logo.png" alt="Nerko" loading="lazy" />
                                    </div>
                                    <div className="uk-flex-1" />
                                </header>
                                <hr className="uk-margin-remove" />
                                <div className="uk-card uk-card-small">
                                    <div className="uk-panel">
                                        <ul className="uk-nav uk-nav-default">
                                            <li><a href="#About" onClick={handleClickCloseMenu}>About Us</a></li>
                                            <li><a href="#Tokenomics" onClick={handleClickCloseMenu}>Tokenomics</a></li>
                                            <li><a href="#P2P" onClick={handleClickCloseMenu}>P2P</a></li>
                                            <li><a href="https://zap.gitbook.io/zap-finance/" target='_blank' onClick={handleClickCloseMenu}>Whitepaper</a></li>
                                            <li>
                                                <a href="https://stake.zapfinance.org/" target='_blank' className="uk-button  uk-button-default uk-button-outline uk-margin-left uk-border">
                                                    <span>Start Now</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OverlayTrigger trigger="click" placement="top" overlay={popoverTop} >
                <img width={90} height={90} src="assets/images/chat-boat.png" alt="" className='sticky' />
            </OverlayTrigger>
            {/* {show
                ?
                <div className={show ? "uk-modal-full uk-modal uk-open" : "uk-modal-full uk-modal"} data-uk-modal style={show ? { display: 'block' } : ""}>
                    <div className="uk-modal-dialog">
                        <div className="uk-position-top uk-position-z-index-negative" data-uk-height-viewport style={{ minHeight: 'calc(100vh)' }}>
                            <div className="uk-position-cover uk-background-cover uk-opacity-10 dark:uk-hidden" data-src="../assets/images/gradient-01.png" data-uk-img />
                            <div className="uk-position-cover uk-background-cover uk-opacity-20 uk-hidden dark:uk-visible" data-src="../assets/images/gradient-01.png" data-uk-img />
                        </div>
                        <button onClick={handleClickClose} className="uk-modal-close-full uk-close-large uk-position-small uk-icon uk-close" type="button" data-uk-close><svg width={20} height={20} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><line fill="none" stroke="#000" strokeWidth="1.4" x1={1} y1={1} x2={19} y2={19} /><line fill="none" stroke="#000" strokeWidth="1.4" x1={19} y1={1} x2={1} y2={19} /></svg></button>
                        <div className="uk-container">
                            <div className="uk-grid uk-flex-center uk-flex-middle uk-child-width-1-2@m uk-section uk-grid-stack" data-uk-grid data-uk-height-viewport style={{ minHeight: 'calc(100vh)' }}>
                                <div className="uk-first-column">
                                    <div className="uk-panel uk-text-center">
                                        <h2 className="uk-h5 uk-h3@s uk-h2@l uk-margin-remove">ZAP Ecosystem</h2>
                                        <div className="uk-grid uk-grid-xsmall uk-grid-small@m uk-child-width-1-2@m uk-margin-medium-top uk-margin-large-top@m" data-uk-grid>
                                            <div className="uk-first-column">
                                                <div className="uk-panel uk-card uk-card-small uk-card-medium@m uk-card-border uk-radius-medium uk-radius-large@m uk-box-shadow-hover-small hover:uk-border-primary">
                                                    <a href="https://stake.zapfinance.org/" target='_blank' className="uk-position-cover" />
                                                    <img src="assets/images/stake.svg" width="100" alt="metamask" />
                                                    <h4 className="uk-text-bold uk-h5@m uk-margin-small-top uk-margin-medium-top@m">Stake Now</h4>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="uk-panel uk-card uk-card-small uk-card-medium@m uk-card-border uk-radius-medium uk-radius-large@m uk-box-shadow-hover-small hover:uk-border-primary">
                                                    <a href="https://p2p.zapfinance.org/" target='_blank' className="uk-position-cover" />
                                                    <img src="assets/images/p2p.svg" width="100" alt="bitgo" />
                                                    <h4 className="uk-text-bold uk-h5@m uk-margin-small-top uk-margin-medium-top@m">P2P</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                :
                ""

            } */}

        </>
    )
}
