import React from 'react'
import CopyBtn from './CopyBtn'
export default function Footer() {

    return (
        <>
            <footer className="uni-footer uk-section uk-section-xlarge@m">
                <div className="uk-container">
                    <div className="uk-panel">
                        {/* <img className="uk-position-top-left" width={32} src="../assets/images/objects/ethereum-01.png" alt="object" style={{ top: '32%', left: '16%' }} />
                        <img className="uk-position-top-right" width={16} src="../assets/images/objects/x.png" alt="object" style={{ top: '8%', right: '16%' }} />
                        <img className="uk-position-bottom-right" width={16} src="../assets/images/objects/circle-01.png" alt="object" style={{ bottom: '24%', right: '40%' }} />
                        <img className="uk-position-bottom-left" width={24} src="../assets/images/objects/circle-03.png" alt="object" style={{ bottom: '-8%', left: '30%' }} /> */}
                        <div className="uk-grid uk-flex-center uk-text-center" data-uk-grid>
                            <div className="uk-width-large@m">
                                <div className="uk-panel">
                                    <a className="uk-logo" href="/">
                                        <img width={200} src="/assets/images/white-logo.png" alt="Nerko" />
                                    </a>
                                    <p className="uk-text-xlarge@m uk-margin-medium-top@m">The world's first 100% decentralized Binary platform.</p>
                                    <ul className="uk-subnav uk-subnav-small uk-flex-center uk-text-gray-40 uk-margin-top uk-margin-medium-top@m">
                                        <li>
                                            <a href="https://twitter.com/unistudioco"><img src="./assets/images/fb.png" alt="" srcset="" /></a>
                                        </li>
                                        <li>
                                            <a href="https://discord.com/unistudioco"><img src="./assets/images/insta.png" alt="" srcset="" /></a>
                                        </li>
                                        <li>
                                            <a href="https://instagram.com/unistudioco"><img src="./assets/images/twt.png" alt="" srcset="" /></a>
                                        </li>
                                        <li>
                                            <a href="https://discord.com/unistudioco"><img src="./assets/images/tele.png" alt="" srcset="" /></a>
                                        </li>
                                        <li>
                                            <a href="https://instagram.com/unistudioco"><img src="./assets/images/github.png" alt="" srcset="" /></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <ul className="uk-subnav uk-subnav-small uk-flex-center uk-text-gray-40 uk-margin-top uk-margin-medium-top@m">
                            <li>
                                <a href="https://bscscan.com/address/0x048935BCA348ff05a1837bcC859430d725F907eF" target='_blank'>ZAP (RED) Contract</a>
                            </li>
                            <CopyBtn address={"0x048935BCA348ff05a1837bcC859430d725F907eF"} />
                            <li>
                                <a href="https://bscscan.com/address/0xB85119fdCbb9CadCDf21f7EB351D305A256BBB20" target='_blank'>ZAP (BLUE) Contract</a>
                            </li>
                            <CopyBtn address={"0xB85119fdCbb9CadCDf21f7EB351D305A256BBB20"} />
                            <li>
                                <a href="https://bscscan.com/address/0x55d398326f99059fF775485246999027B3197955" target='_blank'>USDT Contract</a>
                            </li>
                            <CopyBtn address={"0x55d398326f99059fF775485246999027B3197955"} />
                            <li>
                                <a href="https://bscscan.com/address/0x748b3078848e6Cf5987EbDa29A003C0985540f34" target='_blank'>ZAP Contract</a>
                            </li>
                            <CopyBtn address={"0x748b3078848e6Cf5987EbDa29A003C0985540f34"} />
                        </ul>
                        <hr className="uk-margin-medium uk-margin-3xlarge-top@m" />
                        <div className="uk-panel uk-text-small">
                            <div className="uk-grid uk-child-width-auto@m uk-flex-center uk-flex-between@m uk-text-center uk-text-left@m" data-uk-grid>
                                {/* <div>
                                    <ul className="uk-subnav uk-subnav-small uk-text-muted uk-flex-center">
                                        <li><a href="privacy.html">Privacy policy</a></li>
                                        <li><a href="terms.html">Terms of use</a></li>
                                        <li className="uk-margin-small-left">
                                            <a href="#" data-uk-totop data-uk-scroll><i className="uk-icon uk-icon-small unicon-chevron-up" /></a>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className="uk-flex-first@m uk-flex-center">
                                    <p className="uk-text-muted">© 2024 ZAP. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
