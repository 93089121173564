import React, { useState } from "react";
import copy from "copy-to-clipboard";

const CopyBtn = ({ address }) => {
  const [cpytext, setCpytext] = useState("");
  const copyText = (text) => {
    copy(text);
    setCpytext(text);
    setTimeout(() => {
      setCpytext("");
    }, 3000);
  };
  return (
    <>
      {cpytext === address ? (
        // <button className="btn btn-secondary btn-sm mx-2">
        <i class="fa fa-check"></i>
      ) : (
        // </button>
        // <button className="btn btn-secondary btn-sm mx-2">
        <i className="fa fa-copy" onClick={() => copyText(address)} />
        // </button>
      )}
    </>
  );
};

export default CopyBtn;
