import logo from './logo.svg';
import './App.css';
import Index from './Components/Home/Index';

function App() {
  return (
    <>
      <Index/>    
    </>
  );
}

export default App;
