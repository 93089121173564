import React from 'react'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Hero from './Hero'
import About from './About'
import Ownership from './Ownership'
import Training from './Training'
import P2P from './P2P'
import SmartContract from './SmartContract'
import Convenient from './Convenient'
import FAQ from './FAQ'
import TokneDetail from './TokneDetail'
import Diagram from './Diagram'
import Model from '../Comman/Model'

export default function Index() {
  return (
    <>
      {/* <Model /> */}
      <div className="wrap">
        <Header />
        <Hero />
        <About />
        <Ownership />
        <Training />
        <P2P />
        <Diagram />
        <TokneDetail />
        <SmartContract />
        <Convenient />
        <FAQ />
        <Footer />
      </div>
    </>
  )
}
