import React from 'react'

export default function About() {
    return (
        <>
            <div id="About" className="uni-process uk-section uk-section-xlarge@m uk-panel">
                <div className="uk-container">
                    {/* <header className="uk-grid-xsmall uk-flex-center uk-flex-middle uk-grid" data-uk-grid data-anime="opacity:[0, 1]; translateY:[24, 0]; onview: true; delay: 100;">
                        <div className="uk-panel uk-text-center">
                            <h2 className="uk-h3 uk-h1@m">How it <span className="uk-text-gradient">works!</span></h2>
                        </div>
                    </header> */}
                    <div className="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                        <div className="uk-grid uk-grid-2xlarge uk-grid-3xlarge@m uk-child-width-1-1" data-uk-grid>
                            <div>
                                <div className="uk-panel">
                                    <div className="uk-grid uk-grid-3xlarge@m uk-flex-middle uk-child-width-1-2@m" data-uk-grid>
                                        <div>
                                            <div className="uk-panel uk-image-middle uk-overflow-hidden uk-radius uk-radius-large@m" data-anime="opacity:[0, 1]; translateX:[-24, 0]; onview: -250; delay: 200;">
                                                <img src="../assets/images/about_us.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-panel" data-anime="opacity:[0, 1]; translateX:[24, 0]; onview: -250; delay: 300;">
                                                {/* <span className="uk-h6 uk-h5@m uk-text-gradient">01.</span> */}
                                                <h3 className="uk-h3 uk-h2@m">About us</h3>
                                                <p className="uk-text-large@m uk-margin-medium-top@m">ZAP is a platform that utilizes smart contracts, which are self-executing
                                                    contracts with terms written directly into code.
                                                    The smart contracts on the ZAP platform automatically execute actions once certain predefined conditions are met.</p>
                                                <p className="uk-text-large@m uk-margin-medium-top@m"> ZAP incorporates an auto algorithm for its De-Fi functionalities.The platform aims to provide a decentralized and automated mechanism for various financial activities.
                                                    ZAP’s smart contracts enable secure and transparent transactions without the need for intermediaries.</p>
                                                <p className="uk-text-large@m">ZAP’s De-Fi base and smart contract technology contribute to the overall decentralization and transparency of the platform.</p>
                                                <a href="./assets/zap_presentation.pdf" target='_blank' className="uk-button uk-button-large@m uk-button-gradient uk-margin-small-top">
                                                    <span>Presentation</span>
                                                    <i className="uk-icon-small unicon-arrow-up-right uk-text-bold" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
