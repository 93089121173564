import React from 'react'

export default function FAQ() {
    return (
        <>
            <div id="uni_faq" className="uni-faq uk-section uk-section-large@m uk-panel uk-overflow-hidden uk-padding-2xlarge-bottom@m">
                <div className="uk-container">
                    <header className="uk-grid-xsmall uk-flex-center uk-flex-middle uk-text-center uk-child-width-auto@m uk-grid" data-uk-grid>
                        <div className="uk-panel">
                            <h2 className="uk-h3 uk-h2@s uk-heading-d2@m uk-text-uppercase">Asked <span className="uk-text-gradient">Questions</span></h2>
                        </div>
                    </header>
                    <div className="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                        <div className="uk-grid uk-grid-medium@m uk-child-width-2-2@s" data-uk-grid>
                            <div>
                                <ul className="uk-card uk-card-small uk-card-large@m uk-radius uk-radius-large@m uk-width-2xlarge@m uk-margin-auto uk-border uk-box-shadow-xsmall dark:uk-background-white-5" data-uk-accordion="collapsible: true">
                                    <li>
                                        <a className="uk-accordion-title uk-h5@m" href="#">What is ZAP Protocol?</a>
                                        <div className="uk-accordion-content uk-padding-small-bottom">
                                            <p className="uk-text-small uk-text-large@m uk-text-muted">The ZAP Protocol stands at the forefront of Staking platforms, leveraging the capabilities of the BSC Chain. As a tech entity, we exploit the potential of blockchain tech to create sophisticated decentralized applications (dApps). Our central objective revolves around devising inventive solutions that cater to diverse industries and applications. ZAP Protocol is committed to fully unlocking the capabilities of blockchain technology, striving to produce products that offer significant benefits to our user base.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="uk-accordion-title uk-h5@m" href="#">Which Wallet Should I Utilize?</a>
                                        <div className="uk-accordion-content uk-padding-small-bottom">
                                            <p className="uk-text-small uk-text-large@m uk-text-muted">ZAP Protocol seamlessly integrates with all cryptocurrency wallets. We recommend the following options:
                                            For Mobile Devices (Smartphones, Tablets): Metamask Wallet app is a great choice. For iPhone users, you can download it here. For Android users, please [click here].
                                            <br />
                                            For Desktops/Laptops: You can access it [here]</p>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="uk-accordion-title uk-h5@m" href="#">How Can I Purchase/Sell BSC If I'm New to Cryptocurrencies?</a>
                                        <div className="uk-accordion-content uk-padding-small-bottom">
                                            <p className="uk-text-small uk-text-large@m uk-text-muted">For those unfamiliar with cryptocurrencies, there are several user-friendly approaches to purchasing and trading digital currencies using traditional fiat currencies. These methods are streamlined for simplicity. The initial transaction, where you convert fiat money into digital currency, is expected to be completed in under five minutes. We suggest utilizing a reputable aggregator for cryptocurrency exchanges to streamline and assist in this transaction.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="uk-accordion-title uk-h5@m" href="#">How Can I Register on ZAP Finance?</a>
                                        <div className="uk-accordion-content uk-padding-small-bottom">
                                            <p className="uk-text-small uk-text-large@m uk-text-muted">To register on ZAP, you must first acquire ZAP Blue Tokens.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="uk-accordion-title uk-h5@m" href="#">Can I Register Without a Referral Link?</a>
                                        <div className="uk-accordion-content uk-padding-small-bottom">
                                            <p className="uk-text-small uk-text-large@m uk-text-muted">No, the registration process requires you to enter a address before your account can be successfully created.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="uk-accordion-title uk-h5@m" href="#">Are there multiple pools for staking ZAP?</a>
                                        <div className="uk-accordion-content uk-padding-small-bottom">
                                            <p className="uk-text-small uk-text-large@m uk-text-muted">Yes, various pools are available for different staking durations.</p>
                                        </div>
                                    </li>
                                    <li>
                                        <a className="uk-accordion-title uk-h5@m" href="#">What swapping pairs are available on ZAP Protocol?</a>
                                        <div className="uk-accordion-content uk-padding-small-bottom">
                                            <p className="uk-text-small uk-text-large@m uk-text-muted">Currently, ZAP supports the swapping pair of ZAP(BLUE and RED) to USDT. This pairing allows users to Buy and sell  between ZAP and a stablecoin, offering a P2P convenient way to manage assets within the platform.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
