import React from 'react'

export default function SmartContract() {
    return (
        <>
            <div>
                <img className="uk-width-small uk-flex-center uk-margin-auto uk-margin-medium uk-margin-large@m uk-text-primary" src="/assets/images/divider-02.svg" alt="Divider" data-uk-svg />
                {/* roadmap start */}
                <div id="uni_roadmap" className="uni-roadmap uk-section uk-section-large@m uk-panel uk-overflow-hidden">
                    <div className="uk-container">
                        <header className="uk-grid-xsmall uk-flex-center uk-flex-middle uk-text-center uk-child-width-auto@m uk-grid" data-uk-grid>
                            <div className="uk-panel">
                                <h2 className="uk-h3 uk-h2@s uk-heading-d2@m uk-text-uppercase">Technology of smart contracts <span className="uk-text-gradient"> and non-fungible tokens</span></h2>
                            </div>
                        </header>
                        <div className="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                            <div className="uk-grid uk-grid-row-medium uk-flex-middle" data-uk-grid>
                                <div className="uk-width-5-12@m">
                                    <ul className="uni-roadmap-switcher" data-uk-switcher="connect: #uni-roadmap-nav; animation: uk-animation-slide-bottom-small, uk-animation-slide-top-small" style={{overflowX:"auto", overflowY:"hidden"}}>
                                        <li>
                                            <a href="#" aria-expanded="false">
                                                <h2 className="uk-h4 uk-h3@m uk-text-inherit uk-text-uppercase uk-margin-remove">Autonomy</h2>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" aria-expanded="false">
                                                <h2 className="uk-h4 uk-h3@m uk-text-inherit uk-text-uppercase uk-margin-remove">Unchanging conditions</h2>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" aria-expanded="true">
                                                <h2 className="uk-h4 uk-h3@m uk-text-inherit uk-text-uppercase uk-margin-remove">Transparency</h2>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" aria-expanded="true">
                                                <h2 className="uk-h4 uk-h3@m uk-text-inherit uk-text-uppercase uk-margin-remove">Full automation</h2>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" aria-expanded="true">
                                                <h2 className="uk-h4 uk-h3@m uk-text-inherit uk-text-uppercase uk-margin-remove">Decentralization</h2>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#" aria-expanded="true">
                                                <h2 className="uk-h4 uk-h3@m uk-text-inherit uk-text-uppercase uk-margin-remove">100% online</h2>
                                            </a>
                                        </li>
                                    </ul>    
                                </div>
                                <div className="uk-width-expand@m">
                                    <div className="uk-panel">
                                        <ul id="uni-roadmap-nav" className="uk-switcher">
                                            <li>
                                                <div className="uk-grid uk-child-width-1-1 uk-child-width-2-2@s" data-uk-grid>
                                                    <div>
                                                        <div className="uk-card uk-card-small uk-card-medium@m uk-panel uk-radius uk-radius-large@m uk-border uk-box-shadow-xsmall dark:uk-background-white-5">
                                                            <div className="uk-panel">
                                                                <h3 className="uk-h4 uk-h3@m uk-text-uppercase">Autonomy</h3>
                                                                <p className="uk-text-large@m">The ZAP ecosystem is built around the technology of smart contracts and NFTs, which are completely autonomous and exclude the influence of the human factor.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid uk-child-width-1-1 uk-child-width-2-2@s" data-uk-grid>
                                                    <div>
                                                        <div className="uk-card uk-card-small uk-card-medium@m uk-panel uk-radius uk-radius-large@m uk-border uk-box-shadow-xsmall dark:uk-background-white-5">
                                                            <div className="uk-panel">
                                                                {/* <span className="uk-text-small uk-flex-center uk-flex-column uk-flex-middle uk-background-gradient uk-radius-xsmall uk-text-gray-10 uk-width-2xsmall">Phase 3</span> */}
                                                                <h3 className="uk-h4 uk-h3@m uk-text-uppercase">Unchanging conditions</h3>
                                                                <p className="uk-text-large@m">The algorithm is stored on the blockchain, so no one, even the authors of the idea, can intervene, cancel or change your transactions.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid uk-child-width-1-1 uk-child-width-2-2@s" data-uk-grid>
                                                    <div>
                                                        <div className="uk-card uk-card-small uk-card-medium@m uk-panel uk-radius uk-radius-large@m uk-border uk-box-shadow-xsmall dark:uk-background-white-5">
                                                            <div className="uk-panel">
                                                                {/* <span className="uk-text-small uk-flex-center uk-flex-column uk-flex-middle uk-background-gradient uk-radius-xsmall uk-text-gray-10 uk-width-2xsmall">Phase 5</span> */}
                                                                <h3 className="uk-h4 uk-h3@m uk-text-uppercase">Transparency</h3>
                                                                <p className="uk-text-large@m">The smart contract code is stored in the public domain, and anyone can view the entire transaction history at any time. This ensures fair conditions and reliable statistics that you can rely on.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid uk-child-width-1-1 uk-child-width-2-2@s" data-uk-grid>
                                                    <div>
                                                        <div className="uk-card uk-card-small uk-card-medium@m uk-panel uk-radius uk-radius-large@m uk-border uk-box-shadow-xsmall dark:uk-background-white-5">
                                                            <div className="uk-panel">
                                                                {/* <span className="uk-text-small uk-flex-center uk-flex-column uk-flex-middle uk-background-gradient uk-radius-xsmall uk-text-gray-10 uk-width-2xsmall">Phase 7</span> */}
                                                                <h3 className="uk-h4 uk-h3@m uk-text-uppercase">Full automation</h3>
                                                                <p className="uk-text-large@m">All transactions between community members take place directly from one personal wallet to another. Participants do not have accounts within the system from which to withdraw funds, since ZAP does not store your assets.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid uk-child-width-1-1 uk-child-width-2-2@s" data-uk-grid>
                                                    <div>
                                                        <div className="uk-card uk-card-small uk-card-medium@m uk-panel uk-radius uk-radius-large@m uk-border uk-box-shadow-xsmall dark:uk-background-white-5">
                                                            <div className="uk-panel">
                                                                {/* <span className="uk-text-small uk-flex-center uk-flex-column uk-flex-middle uk-background-gradient uk-radius-xsmall uk-text-gray-10 uk-width-2xsmall">Phase 9</span> */}
                                                                <h3 className="uk-h4 uk-h3@m uk-text-uppercase">Decentralization</h3>
                                                                <p className="uk-text-large@m">No one, not even the creators of the code, can make changes to the work of ZAP smart contracts.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="uk-grid uk-child-width-1-1 uk-child-width-2-2@s" data-uk-grid>
                                                    <div>
                                                        <div className="uk-card uk-card-small uk-card-medium@m uk-panel uk-radius uk-radius-large@m uk-border uk-box-shadow-xsmall dark:uk-background-white-5">
                                                            <div className="uk-panel">
                                                                {/* <span className="uk-text-small uk-flex-center uk-flex-column uk-flex-middle uk-background-gradient uk-radius-xsmall uk-text-gray-10 uk-width-2xsmall">Phase 9</span> */}
                                                                <h3 className="uk-h4 uk-h3@m uk-text-uppercase">100% online</h3>
                                                                <p className="uk-text-large@m">There are no hidden costs or service fees and it's all depend on ZAP Protocol</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-panel uk-margin-medium-top uk-margin-xlarge-top@m">
                            <div className="uk-grid uk-grid-xlarge@m uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-expand@m uk-flex-middle uk-flex-center uk-grid uk-margin-medium-top uk-margin-large-top@s" data-uk-grid>
                                <div><img className="uk-width-xsmall" src="assets/images/metamask.png" alt="Metamask" data-uk-svg /></div>
                                <div><img className="uk-width-xsmall" src="assets/images/06.png" alt="Coinbase" data-uk-svg /></div>
                                <div><img className="uk-width-xsmall" src="assets/images/bscscan.png" alt="Trust Wallet" data-uk-svg /></div>
                                <div><img className="uk-width-xsmall" src="assets/images/cmc.png" alt="BitGo" data-uk-svg /></div>
                                <div><img className="uk-width-xsmall" src="assets/images/coingecko.png" alt="Exodus" data-uk-svg /></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
