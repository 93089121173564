import React from 'react'

export default function P2P() {
    return (
        <>
            <div id="P2P" className="uni-process uk-section uk-section-xlarge@m uk-panel">
                <div className="uk-container">
                    <div className="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                        <div className="uk-grid uk-grid-2xlarge uk-grid-3xlarge@m uk-child-width-1-1" data-uk-grid>
                            <div>
                                <div className="uk-panel">
                                    <div className="uk-grid uk-grid-3xlarge@m uk-flex-middle uk-child-width-1-2@m" data-uk-grid>
                                        <div>
                                            <div className="uk-panel uk-image-middle uk-overflow-hidden uk-radius uk-radius-large@m" data-anime="opacity:[0, 1]; translateX:[-24, 0]; onview: -250; delay: 200;">
                                                <img src="../assets/images/peer-to-peer.gif" alt="890 Nerko's Collective NFT" />
                                            </div>
                                        </div>
                                        <div className="uk-flex-first@m">
                                            <div className="uk-panel" data-anime="opacity:[0, 1]; translateX:[24, 0]; onview: -250; delay: 300;">
                                                {/* <span className="uk-h6 uk-h5@m uk-text-gradient">02.</span>   */}
                                                <h3 className="uk-h3 uk-h2@m">Peer-to-Peer ZAP Network Connection</h3>
                                                <p className="uk-text-large@m uk-margin-medium-top@m">In peer-to-peer (P2P) networking, a group of computers are linked together with equal permissions and responsibilities for processing data. Unlike traditional client-server networking, no devices in a P2P network are designated solely to serve or to receive data.
                                                </p>
                                                <a href="https://p2p.zapfinance.org/" target='_blank' className="uk-button uk-button-large@m uk-button-gradient uk-margin-small-top">
                                                    <span>P2P</span>
                                                    <i className="uk-icon-small unicon-arrow-up-right uk-text-bold" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
