import React from 'react'

export default function TokneDetail() {
    return (
        <>
            <div id="Tokenomics" className="uni-roadmap uk-section uk-section-large@m uk-panel uk-overflow-hidden">
                <div className="uk-container">
                    <header className="uk-grid-xsmall uk-flex-center uk-flex-middle uk-text-center uk-child-width-auto@m uk-grid" data-uk-grid>
                        <div className="uk-panel">
                            <h2 className="uk-h3 uk-h1@m uk-text-uppercase"><span className="uk-text-gradient">Tokenomics</span></h2>
                        </div>
                    </header>
                    <div className="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                        <div className="uk-grid-small uk-grid-medium@m uk-child-width-1-2@m uk-text-muted uk-grid" data-uk-grid>
                            <div>
                                <div className="uni-phase uk-panel uk-overflow-hidden uk-card uk-card-small uk-card-medium@m uk-card-border uk-box-shadow-xsmall uk-radius-medium uk-radius-large@m dark:uk-background-white-5">
                                    {/* <span className="uni-phase-progress uk-text-small uk-text-bold uk-position-top-right uk-position-small uk-text-white uk-background-gradient"><span>0</span>%</span> */}
                                    <div className="uk-panel">
                                        <span className="uni-phase-count uk-text-overline uk-text-gradient">Zap Blue</span>
                                        <h3 className="uni-phase-title uk-h4 uk-h3@m uk-text-uppercase uk-margin-remove-bottom">Zap Blue Token Details</h3>
                                        {/* <p className="uni-phase-description uk-text-large@m">Quality comes first. we took our time to plan out everything and build our production pipeline for a good quality artworks.</p> */}
                                        <hr className="uk-width-2xsmall uk-margin-medium@m" />
                                        <ul className="uk-list uk-text-small uk-text-medium@m">
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}> <strong>Name :</strong>  ZAP</li>
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}>  <strong>Ticker :</strong> Blue</li>
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}>  <strong>Supply :</strong> 21,000,000 BLUE</li>
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}>  <strong>Contract Address :</strong> <a href="https://bscscan.com/token/0xb85119fdcbb9cadcdf21f7eb351d305a256bbb20" target='_balnk'>0xb85...bbb20</a></li>
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}>  <strong>Blockchain :</strong> BSC Mainnet</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="uni-phase uk-panel uk-overflow-hidden uk-card uk-card-small uk-card-medium@m uk-card-border uk-box-shadow-xsmall uk-radius-medium uk-radius-large@m dark:uk-background-white-5">
                                    {/* <span className="uni-phase-progress uk-text-small uk-text-bold uk-position-top-right uk-position-small uk-text-white uk-background-gradient"><span>25</span>%</span> */}
                                    <div className="uk-panel">
                                        <span className="uni-phase-count uk-text-overline uk-text-gradient">Zap RED</span>
                                        <h3 className="uni-phase-title uk-h4 uk-h3@m uk-text-uppercase uk-margin-remove-bottom">Zap RED Token Details</h3>
                                        {/* <p className="uni-phase-description uk-text-large@m">Starting the production on the procedurally generated planets and the smart contract for minting.</p> */}
                                        <hr className="uk-width-2xsmall uk-margin-medium@m" />
                                        <ul className="uk-list uk-text-small uk-text-medium@m">
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}><strong>Name :</strong>  ZAP </li>
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}> <strong>Ticker :</strong> RED</li>
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}> <strong>Supply :</strong>  21,000,000 RED</li>
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}> <strong>Contract Address:</strong> <a href="https://bscscan.com/token/0x048935bca348ff05a1837bcc859430d725f907ef" target='_blank'>0x048...907ef</a></li>
                                            <li className="uk-flex-middle" style={{justifyContent:"space-between"}}> <strong>Blockchain :</strong> BSC Mainnet</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
