import React from 'react'

export default function Diagram() {
    return (
        <>
            <div className="uni-hero uk-section-2xlarge uk-padding-2xlarge-bottom uk-panel uk-overflow-hidden dark:uk-background-gray-100 swiper-parent">
                <div className="uk-position-top" data-uk-height-viewport style={{ minHeight: 'calc(100vh)' }}>
                    <div className="uk-position-cover uk-background-cover uk-opacity-20" data-src="../assets/images/hero-image.jpg" data-uk-img />
                    <div className="uk-position-cover uk-background-cover dark:uk-hidden"  />
                    <div className="uk-position-cover uk-background-cover uk-hidden dark:uk-visible" />
                </div>
                <div className="uk-container">
                    <div className="uk-panel">
                        <img className="uk-position-top-right uk-text-primary uni-animation-bounce uni-animation-delay-large uk-hidden@s" width={42} src="https://unistudio.co/html/nerko/assets/images/objects/star-01.svg" alt="object" style={{ top: '-16%', right: '24%', opacity: 1, transform: 'scale(1)' }} data-uk-svg data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 400;" />
                        <img className="uk-position-top-right uk-text-primary uni-animation-bounce uk-hidden@s" width={28} src="https://unistudio.co/html/nerko/assets/images/objects/star-01.svg" alt="object" style={{ top: '-12%', right: '38%', opacity: 1, transform: 'scale(1)' }} data-uk-svg data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 400;" />
                        <img className="uk-position-top-right uk-text-primary uni-animation-bounce uk-visible@l" width={32} src="https://unistudio.co/html/nerko/assets/images/objects/star-01.svg" alt="object" style={{ top: '2%', right: '2%', opacity: 1, transform: 'scale(1)' }} data-uk-svg data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 400;" />
                        <img className="uk-position-top-left uk-text-secondary uni-animation-bounce uk-visible@l" width={32} src="https://unistudio.co/html/nerko/assets/images/objects/star-01.svg" alt="object" style={{ top: '2%', left: '2%', opacity: 1, transform: 'scale(1)' }} data-uk-svg data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 400;" />
                        <div className="uk-grid uk-grid-2xlarge@m uk-child-width-1-1@m uk-grid-stack" data-uk-grid>
                            <div className="uk-first-column">
                                <div className="uk-panel uk-text-center uk-margin-top">
                                    <h1 className="uk-h2 uk-h1@s uk-heading-d1@m uk-text-uppercase">
                                        USER INTERACTION <br /> WITH  <span className="uk-text-gradient">ZAP</span>
                                    </h1>
                                    {/* <p>Interactive online visualization of active slots showing your unique NFT collection and your financial progress.</p> */}
                                </div>
                            </div>
                            <div className="uk-grid-margin uk-first-column">
                                <div className="uk-panel uk-overflow-hidden">
                                    <img className="uk-position-top-right uk-text-secondary uni-animation-bounce uk-visible@l" width={48} src="https://unistudio.co/html/nerko/assets/images/objects/star-01.svg" alt="object" style={{ top: '6%', right: '9%', opacity: 1, transform: 'scale(1)' }} data-uk-svg data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 400;" />
                                    <img className="uk-position-top-left uk-text-primary uni-animation-bounce uk-visible@l" width={48} src="https://unistudio.co/html/nerko/assets/images/objects/star-01.svg" alt="object" style={{ top: '6%', left: '9%', opacity: 1, transform: 'scale(1)' }} data-uk-svg data-anime="opacity:[0, 1]; scale:[0, 1]; onview: true; delay: 400;" />
                                    <div className="uk-grid-xsmall uk-grid@m uk-flex-middle uk-flex-center uk-grid" data-uk-grid>
                                        {/* <div className="uk-width-3-12 uk-width-4-12@m uk-first-column">
                                            <div className="uk-grid-xsmall uk-grid@m uk-child-width-1-2@m uk-flex-middle uk-grid" data-uk-grid>
                                                <div className="uk-visible@m uk-first-column">
                                                    <div>
                                                        <div className="uk-card uk-card-2xsmall uk-card-default uk-radius-xlarge dark:uk-background-white-10">
                                                            <div className="uk-panel uk-overflow-hidden uk-radius-large">
                                                                <canvas width={600} height={800} />
                                                                <img src="../assets/images/cabinet_preview.webp" alt="Artwork" className="uk-cover" data-uk-cover style={{ height: '210px', width: '210px' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="uk-panel uk-grid-xsmall uk-grid@m uk-grid uk-grid-stack" data-uk-grid>
                                                        <div className="uk-first-column">
                                                            <div className="uk-card uk-card-2xsmall uk-card-default uk-radius-xlarge dark:uk-background-white-10">
                                                                <div className="uk-panel uk-overflow-hidden uk-radius-large">
                                                                    <canvas className="uk-visible@m" width={200} height={170} />
                                                                    <canvas className="uk-hidden@m" width={300} height={298} />
                                                                    <img src="../assets/images/collections/18.jpg" alt="Artwork" className="uk-cover" data-uk-cover style={{ height: '158px', width: '158px' }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="uk-grid-margin uk-first-column">
                                                            <div className="uk-card uk-card-2xsmall uk-card-default uk-radius-xlarge dark:uk-background-white-10">
                                                                <div className="uk-panel uk-overflow-hidden uk-radius-large">
                                                                    <canvas width={300} height={298} />
                                                                    <img src="../assets/images/collections/04.jpg" alt="Artwork" className="uk-cover" data-uk-cover style={{ height: '158px', width: '158px' }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="uk-width-expand">
                                                <img src="../assets/images/diagram.png" width="100%" alt="Artwork" className="uk-cover"  style={{ width: '100%' }} />
                                            {/* <div className="uk-card uk-card-2xsmall uk-card-default uk-radius-xlarge dark:uk-background-white-10">
                                                <div className="uk-panel uk-overflow-hidden uk-radius-large">
                                                    <canvas width={400} height={600} />
                                                </div>
                                            </div> */}
                                        </div>
                                        {/* <div className="uk-width-3-12 uk-width-4-12@m">
                                            <div className="uk-grid-xsmall uk-grid@m uk-child-width-1-2@m uk-flex-middle uk-grid" data-uk-grid>
                                                <div className="uk-first-column">
                                                    <div className="uk-panel uk-grid-xsmall uk-grid@m uk-grid uk-grid-stack" data-uk-grid>
                                                        <div className="uk-first-column">
                                                            <div className="uk-card uk-card-2xsmall uk-card-default uk-radius-xlarge dark:uk-background-white-10">
                                                                <div className="uk-panel uk-overflow-hidden uk-radius-large">
                                                                    <canvas width={300} height={298} />
                                                                    <img src="../assets/images/collections/07.jpg" alt="Artwork" className="uk-cover" data-uk-cover style={{ height: '158px', width: '158px' }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="uk-grid-margin uk-first-column">
                                                            <div className="uk-card uk-card-2xsmall uk-card-default uk-radius-xlarge dark:uk-background-white-10">
                                                                <div className="uk-panel uk-overflow-hidden uk-radius-large">
                                                                    <canvas className="uk-visible@m" width={200} height={170} />
                                                                    <canvas className="uk-hidden@m" width={300} height={298} />
                                                                    <img src="../assets/images/collections/19.jpg" alt="Artwork" className="uk-cover" data-uk-cover style={{ height: '158px', width: '158px' }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="uk-visible@m">
                                                    <div>
                                                        <div className="uk-card uk-card-2xsmall uk-card-default uk-radius-xlarge dark:uk-background-white-10">
                                                            <div className="uk-panel uk-overflow-hidden uk-radius-large">
                                                                <canvas width={600} height={800} />
                                                                <img src="../assets/images/collections/10.jpg" alt="Artwork" className="uk-cover" data-uk-cover style={{ height: '210px', width: '210px' }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
