import React from 'react'

export default function Hero() {
    return (
        <>
            <div id="uni_hero" className="uni-hero uk-section-2xlarge uk-section-xlarge@m uk-padding-remove-bottom@m uk-panel" style={{ backgroundImage: `url("assets/images/hero_banner_bg-old.png")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50% 50%" }}>
                <div className="uk-position-top uk-position-z-index-negative uk-overflow-hidden uk-blend-overlay" data-uk-height-viewport>
                    <img className="uk-position-top-left uk-position-fixed uk-blur-large" style={{ left: '-4%', top: '-4%' }} width={500} src="https://unistudio.co/html/nerko/assets/images/gradient-circle.svg" alt="Circle" />
                    <img className="uk-position-bottom-right uk-position-fixed uk-blur-large" style={{ right: '-4%', bottom: '-4%' }} width={500} src="https://unistudio.co/html/nerko/assets/images/gradient-circle.svg" alt="Circle" />
                </div>
                <div className="uk-position-top uk-position-z-index-negative uk-opacity-50" data-uk-height-viewport>
                    <div className="uk-position-cover uk-background-cover dark:uk-hidden" data-src="../assets/images/gradient-02.png" data-uk-img />
                </div>
                <div className="uk-panel uk-position-z-index">
                    <div className="uk-container">
                        <div className="uk-panel">
                            <div className="uk-grid uk-grid-2xlarge uk-flex-middle uk-flex-between" data-uk-grid data-uk-height-viewport="offset-top: true;">
                                <div className="uk-width-6-12@m">
                                    <div className="uk-panel uk-position-z-index uk-text-center uk-text-left@m" data-anime="opacity:[0, 1]; translateY:[24, 0]; onview: true; delay: 100;">
                                        <h2 className="uk-h2 uk-heading-d3@m uk-margin-small uk-margin@m">Adoption of ZAP is inevitable
                                            Bringing Decentralization to the masses.</h2>
                                        <p className="uk-text-xlarge uk-width-xlarge@m">
                                            <a href="https://bscscan.com/address/0x748b3078848e6cf5987ebda29a003c0985540f34" target='_blank' className='uk-margin-top' style={{wordBreak: "break-all"}}>0x748b3078848e6cf5987ebda29a003c0985540f34</a>
                                        </p>
                                        <a href="https://p2p.zapfinance.org/" target='_blank' className="uk-button uk-button-large@m uk-button-gradient uk-margin-small-top">
                                            <span>Buy Blue</span>
                                            <i className="uk-icon-small unicon-arrow-up-right uk-text-bold" />
                                        </a>&nbsp;
                                        <a href="https://p2p.zapfinance.org/" target='_blank' className="uk-button uk-button-large@m uk-button-gradient uk-margin-small-top">
                                            <span>Sell Red</span>
                                            <i className="uk-icon-small unicon-arrow-up-right uk-text-bold" />
                                        </a>
                                    </div>
                                </div>
                                <div className="uk-width-5-12@m uk-flex-center">
                                    <div className="uk-panel" data-anime="opacity:[0, 1]; translateY:[24, 0]; onview: true; delay: 200;" style={{ width: "100%" }}>
                                        <img src="../assets/images/hero.png" alt="Artwork" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
