import React from 'react'

export default function Training() {
    return (
        <>
            <div id="uni_process" className="uni-process uk-section uk-section-xlarge@m uk-panel">
                <div className="uk-container">
                    <div className="uk-panel uk-margin-medium-top uk-margin-2xlarge-top@m">
                        <div className="uk-grid uk-grid-2xlarge uk-grid-3xlarge@m uk-child-width-1-1" data-uk-grid>
                            <div>
                                <div className="uk-panel">
                                    <div className="uk-grid uk-grid-3xlarge@m uk-flex-middle uk-child-width-1-2@m" data-uk-grid>
                                        <div>
                                            <div className="uk-panel uk-image-middle uk-overflow-hidden uk-radius uk-radius-large@m" data-anime="opacity:[0, 1]; translateX:[-24, 0]; onview: -250; delay: 200;">
                                                <img src="../assets/images/zap_training.png" alt="890 Nerko's Collective NFT" />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="uk-panel" data-anime="opacity:[0, 1]; translateX:[24, 0]; onview: -250; delay: 300;">
                                                {/* <span className="uk-h6 uk-h5@m uk-text-gradient">01.</span> */}
                                                <h3 className="uk-h3 uk-h2@m">ZAP Training</h3>
                                                <p className="uk-text-large@m uk-margin-medium-top@m">ZAP" seems to be a specialized training program aimed at helping individuals unleash their maximum potential. The concept of unlocking one's full potential often involves various aspects of personal development, skill enhancement, and mindset refinement.</p>
                                                {/* <a href="#" className="uk-button uk-button-link uk-button-large@m">
                                                    <span>Learn more</span>
                                                    <i className="uk-icon unicon-arrow-up-right" />
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
